import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["output", "loader", "emailOutput", "welcomeMessage"]

  connect() {
    this.clear_message();
  }

  display_email_message(event) {
    const data = event.detail[0];
    const errorMessage = JSON.parse(data)[0].message;

    this.emailOutputTarget.innerHTML = `<hr/>${errorMessage}`;
  }

  display_error_email_message(event) {
    const data = event.detail[0];
    const errorMessage = JSON.parse(data)[0].message;

    this.emailOutputTarget.innerHTML = `<div class="alert alert-danger text-center mt-2 mb-0">${errorMessage}</div>`;
  }

  clear_error_email_message() {
    this.emailOutputTarget.innerHTML = "";
  }

  clear_message() {
    this.outputTarget.innerHTML = "";
  }

  show_loading(event) {
    this.loaderTarget.innerHTML = `
      <div class="spinner-grow spinner-grow-sm text-warning" role="status">
        <span class="sr-only">Loading...</span>
      </div>`;
  }

  hide_loading() {
    this.loaderTarget.innerHTML = "";
  }

  display_error_message(event) {
    let errorMessage = null;

    // Invalid Authenticity Token
    if (event.detail[2].status == 422) {
      errorMessage = `
        Your browser sent an invalid CSRF Token. Please refresh the page and try again.<br/>
        If this problem persists, try clearing your browser's cookies for this website, or try a different web browser.<hr/>
        If you require futher assistance, please contact IDS Support on
        <a href='tel:+61882012345' class='text-nowrap'>+61 8 8201 2345</a> (9:00am through 5:00pm ACST).
      `;
    } else {
      const data = event.detail[0];
      errorMessage = JSON.parse(data)[0].message;
    }

    this.outputTarget.innerHTML = `<div class="alert alert-danger mt-3" role="alert">${errorMessage}</div>`;
  }
}
