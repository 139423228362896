// this controller is to toggle the forms between being hidden and shown

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["header", "hideable", "loader", "id_text_field"]

  connect() {
    // hide the hideable parts of the forms using js when it loads
    this.hideableTarget.classList.toggle("d-none");
  }

  show_loading(event) {
    this.loaderTarget.innerHTML = `
      <div class="spinner-grow spinner-grow-sm text-warning" role="status">
        <span class="sr-only">Loading...</span>
      </div>`;
  }

  hide_loading(event) {
    this.loaderTarget.innerHTML = "";
  }

  showhide_form(event) {
    this.headerTarget.classList.add("active");

    // toggle the visibility of the rest of the form
    this.hideableTarget.classList.toggle("d-none");

    // focus the id text field of the non-hidden form
    if (!this.hideableTarget.classList.contains("d-none")) {
      this.id_text_fieldTarget.focus();
    }
  }
}
