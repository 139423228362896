// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["output", "hideable", "header", "welcome_message"]

  // clear the alert notice
  clear_notice() {
    this.outputTarget.innerHTML = "";
  }

  // display an error alert
  display_error(event) {
    let errorMessage = null;

    if (event.detail[2].status == 422) {
      errorMessage = `
        Your browser sent an invalid CSRF Token. Please refresh the page and try again.<br/>
        If this problem persists, try clearing your browser's cookies for this website, or try a different web browser.<hr/>
        If you are a student and require assistance, please contact Flinders Connect in person or by phone on:
        <ul>
          <li><a href="tel:1300 354 633" class="text-nowrap">1300 354 633</a> (domestic)</li>
          <li><a href="tel:+61 8 8201 2727" class="text-nowrap">+61 8 8201 2727</a> (international)</li>
        </ul>
        For everyone else, please contact IDS Support on
        <a href='tel:+61882012345' class='text-nowrap'>+61 8 8201 2345</a> (9:00am through 5:00pm ACST).
      `;
    } else {
      const data = event.detail[0];
      errorMessage = JSON.parse(data)[0].message;
    }
      
    this.outputTarget.innerHTML = `<hr/><div class="alert alert-danger" role="alert">${errorMessage}</div>`;
  }

  // sets all form styles to their default state
  clear_form_style() {
    this.headerTargets.forEach(item => item.classList.remove("active"));
    this.hideableTargets.forEach(item => item.classList.add("d-none"));
  }
}
